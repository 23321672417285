import React, { useEffect, useState } from 'react';

import { logger } from 'utils';

type iLoader = {
	loaded: boolean;
	setLoaded: (v: boolean) => void;
};

const LoaderContext = React.createContext<iLoader>({});

const LoaderProvider = (props: any) => {
	const [loaded, setLoaded] = useState(false);

	// Hooks
	useEffect(() => {
		logger('LoaderContext: ', { status: 'ok ' });
	}, []);

	// Methods
	return <LoaderContext.Provider value={{ loaded, setLoaded }}>{props.children}</LoaderContext.Provider>;
};

const useLoader = () => {
	return React.useContext(LoaderContext);
};

export { LoaderProvider, useLoader };
