const errorStyles = 'color: crimson';
const infoStyles = 'color: cyan';
const successStyles = 'color: aquamarine';

export const logger = (title, ...data) => {
	if (process.env.GATSBY_NODE_ENV === 'development') {
		return console.log(`${title}`, ...data);
	}
};

export const logError = (title, ...data) => {
	if (process.env.GATSBY_NODE_ENV === 'development') {
		return console.log(`%c${title}`, errorStyles, ...data);
	}
};

export const logInfo = (title, ...data) => {
	if (process.env.GATSBY_NODE_ENV === 'development') {
		return console.log(`%c${title}`, infoStyles, ...data);
	}
};

export const logSuccess = (title, ...data) => {
	if (process.env.GATSBY_NODE_ENV === 'development') {
		return console.log(`%c${title}`, successStyles, ...data);
	}
};

// Docs:
// https://developer.mozilla.org/en-US/docs/Web/API/Console
