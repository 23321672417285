import React, { useEffect, useState } from 'react';

import { logger } from 'utils';

type iTheme = {
	darkMode: boolean;
	toggle: () => void;
};

const ThemeContext = React.createContext<iTheme>({});

const ThemeProvider = (props: any) => {
	const [darkMode, setDarkMode] = useState(true);

	// Hooks
	useEffect(() => {
		logger('ThemeContext: ', { status: 'ok ' });
	}, []);

	useEffect(() => {
		updateMode(darkMode);
	}, [darkMode]);

	// Methods
	const toggle = () => setDarkMode(!darkMode);

	const updateMode = (darkMode: boolean) => {
		if (typeof window !== 'undefined') {
			console.log('preference: ', window.matchMedia('(prefers-color-scheme: dark)').matches);
			return document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
		}
	};

	return <ThemeContext.Provider value={{ darkMode, toggle }}>{props.children}</ThemeContext.Provider>;
};

const useTheme = () => {
	return React.useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
