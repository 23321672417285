import React, { useEffect, useState } from 'react';

import { logger } from 'utils';

type iDrawer = {
	open: boolean;
	toggle: () => void;
};

const DrawerContext = React.createContext<iDrawer>({});

const DrawerProvider = (props: any) => {
	const [open, setOpen] = useState(false);

	// Hooks
	useEffect(() => {
		logger('drawerContext: ', { status: 'ok', ...props });
	}, []);

	useEffect(() => {
		setOpen(false);
	}, [props?.location?.pathname]);

	// Methods
	const toggle = () => setOpen(!open);

	// Render
	return <DrawerContext.Provider value={{ open, toggle }}>{props.children}</DrawerContext.Provider>;
};

const useDrawer = () => {
	return React.useContext(DrawerContext);
};

export { DrawerProvider, useDrawer };

// Docs
// https://reach.tech/router/api/useLocation
