import React from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';

import 'styles/global.css';
import 'styles/markdown.css';
import 'utils/font-awesome/fa-icons';
import { DrawerProvider } from './src/contexts/drawer-context';
import { LoaderProvider } from './src/contexts/loader-context';
import { ThemeProvider } from './src/contexts/theme-context';

// Wraps root element
export const wrapRootElement = ({ element }) => {
	return (
		<ThemeProvider>
			<LoaderProvider>{element}</LoaderProvider>
		</ThemeProvider>
	);
};

// Wraps every page
export const wrapPageElement = ({ element, props }) => {
	return <DrawerProvider {...props}>{element}</DrawerProvider>;
};

// Docs
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
