import Dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Timezone from 'dayjs/plugin/timezone';

Dayjs.extend(RelativeTime);
Dayjs.extend(Timezone);
Dayjs.extend(AdvancedFormat);

export const dayjs = (value: any) => {
	return Dayjs(value);
};

export const timeElapsed = (startDate: any, endDate: any) => {
	return Dayjs(endDate).diff(startDate, 'ms') + 'ms';
};

export const formatDate = (value: any, format?: string) => {
	return Dayjs(value).format(format || 'MMM Do, YYYY @ hh:mmA');
};

export const formatTimestamp = (value: any) => {
	return Dayjs(value).fromNow(true);
};

export const toDate = (value: any) => {
	return Dayjs(value).toDate();
};

export const toJson = (value: any) => {
	return Dayjs(value).toJSON();
};

export const toYear = (value = new Date()) => {
	return Dayjs(value).year();
};

export const toUnix = (value = new Date()) => {
	return Dayjs(value).valueOf();
};
