import uniqid from 'uniqid';

export const env = process.env.GATSBY_NODE_ENV;

export const timeout = (ms: number) => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

export const getSeconds = () => {
	return Math.floor(Date.now() / 1000);
};

export const getRandomInt = (max = 1000000) => {
	return Math.floor(Math.random() * max);
};

export const getUUID = () => {
	return uniqid.time();
};

export const chunkArray = (array: any, chunkSize: number) => {
	let index = 0;
	const chunks = [];
	while (index < array.length) {
		const chunk = array.slice(index, index + chunkSize);
		index += chunkSize;
		chunks.push(chunk);
	}
	return chunks;
};

export const listBuilder = (count = 3) => {
	const list = [];
	while (list.length < count) list.push(list.length + 1);
	return list;
};

export const computeBytes = (data: number, type = 'kb') => {
	switch (type) {
		case 'gb':
			return parseFloat(data / (1024 / 1024 / 1024)).toFixed(2) + ' gb';

		case 'mb':
			return parseFloat(data / (1024 / 1024)).toFixed(2) + ' mb';

		case 'kb':
			return parseFloat(data / 1024).toFixed(2) + ' kb';
	}
};

export const stripHtmlEntities = (data = '') => {
	return data.replace(/(<([^>]+)>)/gi, '');
};

export const isArray = (data: any) => {
	return Array.isArray(data);
};

export const isObject = (data: any) => {
	return data !== null && data && typeof data === 'object';
};

export const isString = (data: any) => {
	return typeof data === 'string';
};

export const isEmpty = (data: any) => {
	if (data === null || data === undefined) return true;
	if (isArray(data)) return data.length === 0 ? true : false;
	if (isObject(data)) return Object.keys(data).length === 0 ? true : false;
	return data.length === 0 ? true : false;
};

// Docs
// https://www.w3schools.com/js/js_typeof.asp#:~:text=In%20JavaScript%20null%20is%20%22nothing,typeof%20null%20is%20an%20object.
